body {
  margin: 0;
  padding: 20px; /* Adjust padding as needed */
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
  background-image: url("/public/tether.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute space evenly */
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px; /* Adjust padding as needed */
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}

* {
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
}

html, body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Optional: Adjust padding for smaller screens */
@media (max-width: 765px) {
  body, #root {
    padding: 10px; /* Adjust padding as needed */
  }
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  width: 100%;
}

.container {
  width: 100%;
  max-width: 800px;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  margin-bottom: 0px;
  width: 100%;
}

.logo {
  display: block; /* Ensure it behaves like a block element */
  margin: 0 auto; /* Center it horizontally */
  width: 100px; /* Set a fixed width, adjust as needed */
  height: auto; /* Maintain aspect ratio */
}

.socialMedias {
  display: flex; /* Use flexbox for social media links */
  justify-content: center; /* Center them horizontally */
  align-items: center; /* Center icons vertically */
  width: 100%; /* Ensure social media container takes full width */
  margin: 50px; /* Add some space between logo and social media icons */
}

.socialMedias a {
  margin: 0 20px; /* Space between icons */
}

.socialMedias img {
  width: 30px; /* Set a fixed width for icons, adjust as needed */
  height: auto; /* Maintain aspect ratio */
}
.w3m-button {
  display: block;
  max-width: 400px; /* Set maximum width */
  margin: 5px auto; /* Center the button horizontally with margin auto */
}
