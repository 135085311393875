.home {
  min-height: 100vh;
  padding: 0 20px;
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 0px;
}

.countdownWrapper {
  color: #FFFFFF;
  text-align: center;
  font-size: 16px;
}

.Countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.Countdown-col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 25px;
}

.Countdown-col-element {
  color: #FFFFFF;
  font-size: 30px;
}

.header {
  display: flex;
  flex-direction: column; /* Stack the logo and text vertically */
  justify-content: center;
  align-items: center; /* Center both the logo and text */
  font-size: 40px;
}

.walletWrapper {
  width: 200px;
  color: #009393;
  font-size: 16px;
  padding: 5px 30px;
  border: 1px solid black;
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
  background-color: #FFFFFF; /* White background for contrast */
}

.logo {
  width: 250px;
  margin-bottom: 0px;
}

.socialMedias {
  width: 200px;
  text-align: right;
}

.socialMedias a {
  margin: 0 0px;
}

.socialMedias img {
  width: 100px;
}

.slogan {
  font-size: 18px;
  line-height: 14px;
  color: #000000;
  text-align: center;
  font-weight: 600;
}

.audit {
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  padding: 10px;
  background: #5B5A5D; /* Gray background */
  border-radius: 20px;
  width: 200px;
  margin: 20px auto;
}

.mainContent {
  width: 100%;
  max-width: 800px;
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.w3m-button-container {
  display: flex;              /* Enable flexbox layout */
  justify-content: center;    /* Center content horizontally */
  align-items: center;        /* Center content vertically */
  background-color: black;    /* Background color */
  padding: 10px;              /* Add some padding */
  border-radius: 20px;        /* Rounded corners */
  width: auto;                /* Use auto or a specific width */
}

.w3m-button {
  color: white;               /* Text color */
  background-color: #009393; /* Button background color */
  padding: 0px 60px;         /* Button padding */
  border: none;               /* No border */
  border-radius: 10px;        /* Button rounded corners */
  cursor: pointer;            /* Pointer cursor on hover */
  text-align: center;         /* Center text */
}


.box {
  width: 100%;
}

.leftBox {
  padding: 20px;
  max-width: 100%;
  background: linear-gradient(to bottom, #009393, #005f5b); /* Top-down gradient with a darker shade at the bottom */
  border-radius: 20px;
  border: 3px solid #b08e47; /* Subtle gold border */
  color: #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.rightBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(to bottom, #5B5A5D, #3a393c); /* Top-down gradient with a darker gray at the bottom */
  color: #FFFFFF;
  border-radius: 20px;
  border: 3px solid #b08e47; /* Matching border with left box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}


.dataRow1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  color: #000000; /* Green text */
  font-size: 18px;
  font-weight: 600;
  line-height: 35px;
}

.dataRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
  color: #009393; /* Green text */
  font-size: 18px;
  font-weight: 600;
  line-height: 35px;
}

.dataRow .value {
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF; /* White text for important values */
}

.dataRow1 .value {
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF; /* White text for important values */
}

.ant-input-affix-wrapper {
  height: 55px;
  margin: 0;
  position: relative;
  border: 2px solid #009393;
  box-shadow: none;
  backdrop-filter: blur(20px);
  box-sizing: border-box;
  border-radius: 40px;
  background: #5B5A5D; /* Gray background */
}

.ant-input-affix-wrapper .ant-input-suffix,
.ant-input-affix-wrapper .ant-input-prefix {
  color: #FFFFFF; /* White icons */
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
}

.ant-input-affix-wrapper .ant-input {
  border: none;
  color: #FFFFFF;
  background: transparent;
  font-size: 16px;
  font-weight: 600;
  line-height: 55px;
  text-align: center;
  padding-left: 25px;
}

.ant-input-affix-wrapper:hover {
  border-color: #009393;
}

.ant-input-affix-wrapper-focused {
  border: 2px solid #009393;
}

.buttonContainer {
  display: flex;
  min-width: 250px;
  width: 100%;
  align-items: center; /* Centers child elements vertically (optional) */
  justify-content: center; /* Center child elements within the container */
  gap: 10px; /* Adds space between buttons */
  margin: 0 auto; /* Centers the container itself within the parent */
  padding-bottom: 10px;
}


.buyButton {
  border: 2px solid #5B5A5D;
  max-width: 50%;
  border-radius: 30px;
  color: #009393;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  padding: 5px 0;
  text-align: center;
  margin-top: 10px;
  background-color: #FFFFFF;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: inline-block;
  flex-grow: 1; /* Optional: Makes both buttons equally wide */
}



.buyButton:hover {
  cursor: pointer;
  border: 2px solid #FFFFFF;
  background-color: #5B5A5D; /* Green background on hover */
  color: #FFFFFF; /* White text on hover */
}

.actionWrapper {
  margin-top: 30px;
  border-top: 1px dashed #009393;
  padding-top: 10px;
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actionButtons div {
  width: calc(50% - 10px);
  text-align: center;
  border: 2px solid #009393;
  color: #009393;
  font-weight: bold;
  font-size: 16px;
  padding: 8px 0;
  border-radius: 30px;
  background-color: #FFFFFF; /* White background */
}

.actionButtons div:hover {
  cursor: pointer;
  border: 2px solid #FFFFFF;
  background-color: #009393; /* Green background on hover */
  color: #FFFFFF; /* White text on hover */
}



.contractInfo img {
  width: 25%;
}

.contractInfo .data {
  width: 100%;
}

.contractInfo .data h1 {
  color: #009393;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0px;
  margin: 0px;
}

.contractInfo .data .dataRow {
  font-size: 17px;
}

.referral {
  background: #009393;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px; 
}

.referral h1 {
  color: #FFFFFF;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
}

.referral p {
  color: #FFFFFF;
}

.refWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.referralLink {
  border: 2px solid #FFFFFF;
  border-radius: 40px;
  padding: 5px 20px;
  color: #FFFFFF;
  flex-grow: 2;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 70%;
}

.copyButton {
  border: 2px solid #FFFFFF;
  border-radius: 40px;
  padding: 5px 20px;
  color: #FFFFFF;
}

.copyButton:hover {
  cursor: pointer;
  border: 2px solid #009393;
  background-color: #009393;
  color: #FFFFFF;
}

@media (max-width: 800px) {
  .mainContent {
    flex-direction: column;
    align-items: center;
  }
  
  .box {
    width: 100%;
  }
}

@media (max-width: 765px) {
  .header {
    flex-direction: column;
    align-items: center;
    padding: 0px 0 0 0;
  }
  
  .walletWrapper {
    margin-left: auto;
    width: 150px;
    font-size: 15px;
    padding: 5px 0;
  }
  
  .logo {
    width: 300px;
    margin: 0 auto;
  }
  .logoWrapper {
    display: flex;
    flex-direction: column; /* Stack the logo and text vertically */
    justify-content: center;
    align-items: center; /* Center both the logo and text */
  }
  
  .logoText {
    font-size: 40px; /* Adjust font size as needed */
    font-weight: 600;
    color: #000000; /* Color of the text */
    margin-top: 0px; /* Add space between the logo and text */
    text-align: center; /* Center the text */
    font-weight: bold;
  }
  
  .socialMedias {
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
  
  .socialMedias a {
    margin: 0 20px;
  }
  
  .socialMedias img {
    width: 30px;
  }
  
  .slogan {
    line-height: 20px;
  }
  
  .mainContent {
    margin: 30px auto 0 auto;
    align-items: center;
    gap: 10px;
  }
  
  .referral {
    margin-top: 10px;
  }
}
