.Countdown{
  margin: 0 auto;

  /* padding-bottom: 20px; */
}

.Countdown-col{
  display: inline-block;
}

.Countdown-col-element{
  display: inline-block;
  margin: 0 0;
  font-weight: 600;
}
